import { getRoutePathByItem } from "@constants";

// don't change THUMBNAILS name/type, it's used by `pages-loader` webpack loader
// see scripts/webpack/loaders/pages-loader.js
// prototype: device/name => thumbnail
const Campaign_THUMBNAILS = {
  "desktop/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAgEAABAwIHAAAAAAAAAAAAAAACAAEREkEDBBMhIpHB/8QAFgEAAwAAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwCUGjHNymbDPqATkjcRKh9q26Spw3EEDKXaynVP/9k=",
  "desktop/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFgAAAwAAAAAAAAAAAAAAAAAAAgME/8QAHBABAAEEAwAAAAAAAAAAAAAAAQIAERIhAxOR/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCrFbNypFdXG5sza0EBCFtxPKC//9k=",
  "desktop/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFgAAAwAAAAAAAAAAAAAAAAAAAQIF/8QAHBABAAIDAAMAAAAAAAAAAAAAAQIRAAMSBEFh/8QAFAEBAAAAAAAAAAAAAAAAAAAAAf/EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIRAxEAPwBtXlT2zlCavI4Q0dkyWuZyhyq3eIT7h6F+3WBf/9k=",
  "desktop/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAgMEBf/EAB8QAAICAQQDAAAAAAAAAAAAAAECAAMRBBIhMRNBsf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8AoJHDej9mWmffqbRYQp2gDrERFWnc2UK7dmFCtu4MASAxkUi6jyPlHxxjBESph+nUpQqkciB//9k=",
  "desktop/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwIE/8QAIxAAAQQBAgcBAAAAAAAAAAAAAQACAxEhBBITMTJicYGRsf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A2TycPa4kdQ5+1KsIX2M/iAnSUSAL8KCJdO6WINedubwmgNQSJMXSjQbd3fEH/9k=",
  "desktop/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAUC/8QAHhAAAgICAgMAAAAAAAAAAAAAAQIDIQAREjEEE1H/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8ATFK58R5GQ+wA0Rq9YhiKaSSFzKvEg1WskLGjMpIdQCT2ckrOwCNx13ma1AZ5W0QVrX3GiJtCit4F/9k=",
  "desktop/bottom-4": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDAREAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIRQYET/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AORMO840BXjQGYDyA250n1gTwCgAAA//2Q==",
  "mobile/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwEEBf/EACAQAAEEAQQDAAAAAAAAAAAAAAEAAgMRExQhI0FRUmP/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AyAwvJrpBOJ/hAaBY5sRNMjdfu20Cas0eCDf5hBWQf//Z",
  "mobile/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwECBP/EACEQAAICAgEEAwAAAAAAAAAAAAECABESMQMEEyEyUXGh/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEQMRAD8A0lXPqrfYEkPnzFUca3YhTBYOfPc/IE/T0iAsxJOwx1EI5WyBUVr4glRQAFyL/9k=",
  "mobile/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwECBP/EACIQAAEEAQMFAQAAAAAAAAAAAAEAAgMRBCExYRITIkFxgf/EABcBAQEBAQAAAAAAAAAAAAAAAAIBAwD/xAAaEQEBAAIDAAAAAAAAAAAAAAAAAREhAhIx/9oADAMBAAIRAxEAPwB2ZkhrysHhZ9qWCFz5BY6iCn7EGYWe4238VwjBKTHk1bttNVnN8TuquckFjRR0Fb8UlKKW5wYK7bj+q5c//9k=",
  "mobile/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAMF/8QAHxAAAgIBBAMAAAAAAAAAAAAAAQIAAyEREkFRBDGh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwBBON3Y+zLTOv8AJtFjaNtwMYiIZUxepGPsgEwqS271YZAY6kSKlbQtrEq7LrxxEphNQK1IvQAgf//Z",
  "mobile/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQB/8QAHxAAAQQCAgMAAAAAAAAAAAAAAQACAxESITFhIpHB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwCueTBmV2QW/VKsIZBQyICAjKLNb7UGPgziLZDyQdIJ52uz8bodrOtDa19cH2g//9k=",
  "mobile/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAQQFA//EACAQAAICAgICAwAAAAAAAAAAAAECAxEAIRIxBEEiUXH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8A38SZ5XIljK0LGiLyoMU7yPIrR8QvR+94CBRnJZStX7OBa5pdjWj3mWiU0jj0K/ctIln4miBeQf/Z"
};

const getThumbnailByKey = imgPath => {
  const key = Object.keys(Campaign_THUMBNAILS).find(
    k => imgPath.indexOf(k) !== -1
  );

  return Campaign_THUMBNAILS[key] || false;
};

export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.Campaign;

  const re = new RegExp(`^v\\d+\\/${imgParams.cloudinary.path}\\/`);

  const buildItem = (item, props = {}, loading = null) => ({
    img: {
      src: (item.IMG || "").replace(re, ""),
      thumbnail: getThumbnailByKey(item.IMG),
      fluid: true,
      width: "100%",
      height: "100%",
      title: item.TITLE,
      importance: "high",
      loading,
      cloudinary: imgParams.cloudinary,
      sizes: props.sizes,
      aspect: props.aspect || 1,
      video: Boolean(item.VIDEO)
    },
    href: getRoutePathByItem(pathfinder, item)
  });

  const filterItem = (item, defaultValue = true) =>
    "undefined" === typeof item.ENABLED ? defaultValue : Boolean(item.ENABLED);

  const buildButton = button => {
    const href = getRoutePathByItem(pathfinder, button);

    if (!button.TITLE || !button.ENABLED || !href) {
      return false;
    }

    return {
      button: {
        block: true,
        variant: button.VARIANT,
        className: "px-1",
        onClick: e => {},
        href: getRoutePathByItem(pathfinder, button)
      },
      title: button.TITLE,
      icon: {
        transform: "right-2",
        icon: "angle-right"
      }
    };
  };

  const sizes = {
    carousel: {
      mobile: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1207
        },
        aspect: 0.8191
      },
      desktop: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1424
        },
        aspect: 0.3525
      }
    },
    bottomImg: {
      sizes: {
        mobilePortrait: 390,
        mobileLandscape: 473,
        tabletPortrait: 213,
        tabletLandscape: 293,
        desktop: 298
      },
      aspect: 0.959
    }
  };

  const header =
    _i18n.header.TITLE || _i18n.header.TEXT
      ? { title: _i18n.header.TITLE, text: _i18n.header.TEXT }
      : null;

  const images = ["carousel", "bottomImg"].reduce(
    (carry, key) =>
      Object.assign(carry, {
        [key]: ["mobile", "desktop"].reduce(
          (carry, device) =>
            Object.assign(carry, {
              [device]: _i18n[key][device]
                .filter(item => filterItem(item))
                .map((item, index) =>
                  buildItem(
                    item,
                    sizes[key][device],
                    key === "bottomImg" || !index ? "eager" : null
                  )
                )
            }),
          {}
        )
      }),
    {}
  );

  const buttons = _i18n.buttons.map(buildButton).filter(Boolean);

  return {
    ...images,
    header,
    buttons
  };
};
