import TitledParagraph from "@components-core/TitledParagraph";
import {
  findPathBySelectorType,
  PRODUCT_SELECTOR_TYPE_CATEGORY
} from "@constants";
import { escapeReact } from "@utils/react";

export default ({ imgParams, i18n, pathfinder }) => {
  const _i18n = i18n.pages.BuyGuide;

  const escapeReactRoute = str => escapeReact(str, pathfinder);

  const buildFeaturedProductCategory = (slug, imgName) => ({
    text: null,
    url: findPathBySelectorType(
      pathfinder,
      PRODUCT_SELECTOR_TYPE_CATEGORY,
      slug
    ),
    img: {
      src: imgParams.imgDir + "/pages/buy-guide/".concat(imgName),
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 336,
        mobileLandscape: 501,
        tabletPortrait: 336,
        tabletLandscape: 456,
        desktop: 703
      },
      aspect: 0.7425,
      version: true
    },
    default_colspan: 6,
    className: { card: "h-auto" }
  });

  return {
    lead: {
      title: _i18n.lead.TITLE,
      text: escapeReactRoute(_i18n.lead.TEXT)
    },
    servingTemp: {
      title: _i18n.servingTemp.TITLE,
      text: escapeReactRoute(_i18n.servingTemp.TEXT)
    },
    servingTempImg: {
      src: _i18n.servingTemp.IMG
        ? imgParams.imgDir + "/pages/buy-guide/".concat(_i18n.servingTemp.IMG)
        : null,
      importance: "high",
      cloudinary: imgParams.cloudinary,
      sizes: {
        mobilePortrait: 345,
        mobileLandscape: 510,
        tabletPortrait: 690,
        tabletLandscape: 930,
        desktop: 1110
      },
      aspect: 0.351,
      version: true,
      title: _i18n.servingTemp.TITLE
    },
    goldenRules: {
      title: _i18n.goldenRules.TITLE,
      items: [
        {
          title: null,
          img: null,
          default_colspan: 2,
          colspan: 6,
          paragraphs: _i18n.goldenRules.paragraphs.map(para => ({
            title: para.TITLE,
            text: escapeReactRoute(para.TEXT)
          }))
        }
      ]
    },
    featuredProductCategory: {
      lead: {
        title: _i18n.featuredProductCategory.lead.TITLE,
        text: escapeReactRoute(_i18n.featuredProductCategory.lead.TEXT)
      },
      items: _i18n.featuredProductCategory.items.children.map((child, i) => ({
        ...buildFeaturedProductCategory(child.CATEGORY_SLUG, child.IMG),
        title: _i18n.featuredProductCategory.items.TITLE,
        alt: _i18n.featuredProductCategory.items.children[i].TITLE,
        children: {
          as: TitledParagraph,
          props: {
            title: _i18n.featuredProductCategory.items.children[i].TITLE,
            text: escapeReactRoute(
              _i18n.featuredProductCategory.items.children[i].TEXT
            )
          }
        }
      }))
    },
    faq: {
      lead: {
        title: _i18n.faq.lead.TITLE,
        text: escapeReactRoute(_i18n.faq.lead.TEXT)
      },
      items: _i18n.faq.items.map(item => ({
        title: item.TITLE,
        text: escapeReactRoute(item.TEXT)
      }))
    }
  };
};
